
    <div class="col-12">
        <div class="card-box" *ngIf='loading'>
            <div class="responsiveLoading" >
                <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
            </div>
        </div>
        <div class="card-box" *ngIf='!loading'>
            <h3>About Us
                <span class="buttonModale">
                    <!-- <app-delete-data (childAdded)="onChildAdded($event)"></app-delete-data> -->
                </span>
            </h3>

            <div class="row">
                <div class="col-12">
                    <div class="p-20">
                        <form class="form-horizontal" >
                            <div class="form-group row">
                                <div class="col-lg-7">
                                    <div class="form-group">
                                        <label for="userName">About Us<span class="text-danger">*</span></label>
                                                <textarea rows="5"  required
                                                class="form-control"  name="about_us"  ngModel [(ngModel)]="about.about_us"></textarea>
                                    </div>
                                </div>
                                <div class="col-md-6" ng-app>
                                    <div class="form-group">
                                        <label class=" col-form-label">Phone Number</label>
                                        <input type="text" required
                                                class="form-control"  name="phone_number" ngModel [(ngModel)]="about.phone_number">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <label for="userName">Share String<span class="text-danger">*</span></label>
                                        <input type="text" required
                                                class="form-control"  name="share_string" ngModel [(ngModel)]="about.share_string">
                                    </div>  
                                </div>
                                <div class="col-lg-12" >
                                    <div class="alert" role="alert" #errorDiv>
                                         {{errorMessage}}
                                      </div>
                                </div>
                                
                            </div>
                            
                            
                            <div class="col-lg-12">
                                <button style="float: right;" type="button" [disabled]="loadingButton" class="btn btn-info waves-effect waves-light" (click)="AddAboutSUbmit()">edit</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
            <!-- end row -->

        </div> <!-- end card-box -->
    </div><!-- end col -->

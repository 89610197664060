import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AboutResponse } from 'src/app/components/models/about';

@Injectable({
  providedIn: 'root'
})
export class ApiAboutService {

  BaseUrl ='http://api.tato.apishipping.iraq-soft.info/'
  token :string;
  lang :string;  
  constructor(private http: HttpClient) { }

  public getAbout():  Observable<AboutResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<AboutResponse>(this.BaseUrl+ 'admin/admin_general/about_us/get' ,{headers});
  }


  public addAbout(body :FormData):  Observable<AboutResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<AboutResponse>(this.BaseUrl+ 'admin/admin_general/about_us/update' ,body,{headers});
  }
}

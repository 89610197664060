import { Component, OnInit } from '@angular/core';
import { ApiAboutService } from 'src/app/services/about/api-about.service';
import Swal from 'sweetalert2';
import { DataAbout } from '../../models/about';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  loading = true;
  loadingButton = false;
  errorMessage :string;
  about :DataAbout;
  bodyData : FormData = new FormData;
  constructor(private http : ApiAboutService) { }

  ngOnInit(): void {
    this.http.getAbout().subscribe(response =>{
      
      this.about = response.data;
      this.loading = false;
    });
  }
  AddAboutSUbmit(){

    this.loadingButton = true
    this.bodyData.append('about_us' ,this.about.about_us ),
    this.bodyData.append('phone_number' ,this.about.phone_number ),
    this.bodyData.append('share_string' ,this.about.share_string ),
    this.http.addAbout(this.bodyData).subscribe(response =>{
      this.loadingButton = false;
      if(response.status == 1){
        Swal.fire(
          'about is Successfully'
        )
      }else{
        this.errorMessage ='* '+ response.message;
      }
      
    })
  }

}
